<template>
  <div class="container">
    <div class="title">
      <h1>{{noticeDetail.title}}</h1>
    </div>
    <div class="time">{{noticeDetail.publishTime}}<!-- <span>发布者：管理员</span>--></div>
    <div class="content">{{noticeDetail.content}}</div>
  </div>
</template>
<script>
  import { Toast } from 'vant'
  export default {
    name: 'MessageDetail',
    data () {
      return{
        noticeId: null, // 文章id
        noticeDetail: [] // 文章内容
      }
    },
    created(){

    },
    mounted(){
      console.log('消息详情')
      console.log('消息ID：'+this.$route.query.id)
      this.noticeId = this.$route.query.id
      this.getDetail()
    },
    methods: {
      // 获取消息详情
      getDetail() {
        let me = this
        this.$get({
          url: 'userNotice/findNoticeDetail',
          params: {
            token: this.$global.token,
            noticeId: this.noticeId
          }
        }).then( rsp => {
          console.log(rsp)
          me.noticeDetail = rsp
        }).catch( error => {
          console.log(error)
          Toast(error.message)
        })
      }
    }
  }
</script>
<style scoped lang="scss">
  .container{
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding: 10px 20px 50px 20px;
    box-sizing: border-box;
    color: #888;
    .title{
      color: #333;
      h1{
        font-size: 20px;
      }
    }
    .time{
      font-size: 14px;
      padding: 10px 0 20px 0;
      span{
        margin-left: 20px;
      }
    }
    .content{
      font-size: 16px;
    }
  }
</style>
